import React, { useState, useRef, useEffect, RefObject, ReactNode } from 'react';
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage, useField, FormikErrors } from 'formik';
import axios from 'axios';
import scrollToElement from 'scroll-to-element';
import * as Yup from 'yup';

import Layout from '../components/layout';
import SEO from '../components/seo';

function CustomSelect({
  isMulti = false,
  options,
  placeholder,
  innerRef,
  field: propsField,
}: {
  isMulti?: boolean;
  options: { value: string; label: string }[];
  placeholder: string;
  innerRef: RefObject<Select>;
  field: typeof Field;
}) {
  const [field, , helpers] = useField(propsField);

  return (
    <Select
      ref={innerRef}
      options={options}
      name={field.name}
      inputId={field.name}
      placeholder={placeholder}
      value={
        options && isMulti
          ? options.filter(option => field.value.indexOf(option.value) >= 0)
          : options.find(option => option.value === field.value)
      }
      onChange={option => helpers.setValue(isMulti ? option.map(item => item.value) : option.value)}
      onBlur={field.onBlur}
      isMulti={isMulti}
      styles={{
        placeholder: provided => ({ ...provided, whiteSpace: 'nowrap' }),
        menu: provided => ({ ...provided, color: '#000000' }),
      }}
    />
  );
}

const schema = {
  name: Yup.string().required('Required').max(150, 'Max-length 150 characters'),
  email: Yup.string().email('Must be a valid email address').required('Required'),
  level: Yup.array().min(1, 'Please select at least one option').of(Yup.string()),
  days: Yup.array().min(1, 'Please select at least one day').of(Yup.string()),
  time: Yup.array().min(1, 'Please select at least one time').of(Yup.string()),
  message: Yup.string().required('Required').max(500, 'Max-length 500 characters'),
};

type IProps = {
  name: string;
  type: string;
  label: string;
  width: string;
  as?: string;
  component?: ReactNode;
  selectOptions?: { isMulti?: boolean; options: { value: string; label: string }[] };
  errors: FormikErrors<typeof schema>;
  submitCount: number;
  isSubmitting: boolean;
};

type FormValues = Record<string, string | string[]>;

function InputGroup({
  name,
  type,
  label,
  width,
  as,
  component,
  selectOptions,
  errors,
  submitCount,
  isSubmitting,
}: IProps) {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (submitCount > 0 && errors && isSubmitting) {
      const firstError = Object.keys(errors)[0];
      if (firstError === name && ref.current) {
        if (selectOptions) {
          ref.current.select.focus();
          scrollToElement(ref.current.select.inputRef, {
            offset: -40,
            ease: 'linear',
            duration: 500,
          });
        } else {
          scrollToElement(ref.current, {
            offset: -40,
            ease: 'linear',
            duration: 500,
          });
          ref.current.focus();
        }
      }
    }
  }, [submitCount, errors]);

  return (
    <div className={`flex flex-col mb-2 md:p-2 w-full md:w-${width}`}>
      <label htmlFor="name" className="mb-2 font-semibold lg:text-lg">
        {label}
      </label>
      <Field
        name={name}
        innerRef={ref}
        id={name}
        type={type}
        as={as}
        component={component}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectOptions}
        className={`rounded py-2 mb-2 px-2 placeholder-gray-500 text-black ${
          as === 'textarea' ? 'resize-none h-36' : ''
        }`}
        placeholder={`Enter your ${name}`}
      />
      <ErrorMessage name={name} render={msg => <p className="text-red mt-2 font-semibold">{msg}</p>} />
    </div>
  );
}

function FormikForm({ onSubmit }: { onSubmit: (values: FormValues) => void }) {
  const initialValues = {
    name: '',
    email: '',
    level: [],
    days: [],
    time: [],
    message: '',
    'form-name': 'contact-form',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(schema)}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form
          data-netlify
          name="contact-form"
          data-netlify-honeypot="bot-field"
          className="drawn-border p-5 md:w-auto mx-auto rounded md:flex md:flex-wrap md:justify-between max-w-screen-lg"
        >
          <Field type="hidden" name="bot-field" />
          <Field type="hidden" name="form-name" />
          <InputGroup
            submitCount={submitCount}
            isSubmitting={isSubmitting}
            errors={errors}
            name="name"
            type="text"
            label="Name"
            width="1/2"
          />
          <InputGroup
            submitCount={submitCount}
            isSubmitting={isSubmitting}
            errors={errors}
            name="email"
            type="text"
            label="Email"
            width="1/2"
          />
          <InputGroup
            submitCount={submitCount}
            isSubmitting={isSubmitting}
            errors={errors}
            name="level"
            component={CustomSelect}
            selectOptions={{
              isMulti: true,
              options: [
                {
                  value: '11+',
                  label: '11+',
                },
                {
                  value: 'SATs Preparation',
                  label: 'SATs Preparation',
                },
                {
                  value: 'Subject Catch Up',
                  label: 'Subject Catch Up',
                },
                {
                  value: 'Accelerated Learning',
                  label: 'Accelerated Learning',
                },
                {
                  value: 'Functional Skills',
                  label: 'Functional Skills',
                },
                {
                  value: 'Fundamental Skills for Teachers',
                  label: 'Fundamental Skills for Teachers',
                },
              ],
            }}
            label="Level"
            width="full"
          />
          <InputGroup
            submitCount={submitCount}
            isSubmitting={isSubmitting}
            errors={errors}
            name="days"
            component={CustomSelect}
            selectOptions={{
              isMulti: true,
              options: [
                {
                  value: 'Monday',
                  label: 'Monday',
                },
                {
                  value: 'Tuesday',
                  label: 'Tuesday',
                },
                {
                  value: 'Wednesday',
                  label: 'Wednesday',
                },
                {
                  value: 'Thursday',
                  label: 'Thursday',
                },
                {
                  value: 'Friday',
                  label: 'Friday',
                },
                {
                  value: 'Saturday',
                  label: 'Saturday',
                },
                {
                  value: 'Sunday',
                  label: 'Sunday',
                },
              ],
            }}
            label="Available Days"
            width="1/2"
          />
          <InputGroup
            submitCount={submitCount}
            isSubmitting={isSubmitting}
            errors={errors}
            name="time"
            component={CustomSelect}
            label="Available Times"
            width="1/2"
            selectOptions={{
              isMulti: true,
              options: [
                {
                  value: 'morning',
                  label: 'Morning',
                },
                {
                  value: 'afternoon',
                  label: 'Afternoon',
                },
                {
                  value: 'evening',
                  label: 'Evening',
                },
              ],
            }}
          />
          <InputGroup
            submitCount={submitCount}
            isSubmitting={isSubmitting}
            errors={errors}
            name="message"
            as="textarea"
            label="Message"
            width="full"
          />

          <button
            className="bg-contrast hover:bg-contrast-dark text-white w-full rounded py-2 transition ease-in-out duration-500"
            type="submit"
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
}

function Contact() {
  const [notificationMessage, setNotificationMessage] = useState<string | null>(null);

  const handleSubmit = async (values: FormValues) => {
    setNotificationMessage('Submitting form . . .');
    const encodedValues = Object.keys(values)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(values[key].toString())}`)
      .join('&');

    try {
      await axios.post('/', encodedValues, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      setNotificationMessage('Form submitted successfully. I hope to respond within 48 hours.');
    } catch (err) {
      setNotificationMessage('Oops... something went wrong and the form was not submitted. Please try again.');
    }
  };

  return (
    <Layout heading="Contact Mrs Bridges" subheading="Use the form below to get in touch with me">
      <SEO title="Contact" />
      <div className="py-8">
        {notificationMessage ? (
          <div className="text-center px-12 py-8 max-w-md mx-auto drawn-border">
            <p className="mb-8 text-lg">{notificationMessage}</p>
            <button
              className="bg-contrast hover:bg-contrast-dark text-white w-full rounded py-2 transition ease-in-out duration-500 max-w-xs"
              type="button"
              onClick={() => setNotificationMessage(null)}
            >
              Dismiss
            </button>
          </div>
        ) : (
          <FormikForm onSubmit={handleSubmit} />
        )}
      </div>
    </Layout>
  );
}

export default Contact;
